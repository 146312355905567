<template>
  <div class="bbr-progrm-plans">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="$attrs.id"> {{ plan.name }} </span>
        <span v-else> Add New Plan </span>
      </template>

      <template v-slot:actions>
        <div v-if="hasPrograms && isCompleted">
          <arrow-button
            :done="stage > 1"
            :to="{
              name: 'plan.details',
              params: { id: $attrs.id },
            }"
            arrow-right
            replace
          >
            Plan Details
          </arrow-button>
          <arrow-button
            :done="stage > 3"
            :to="{
              name: 'plan.programs',
              params: { id: $attrs.id },
            }"
            arrow-left
            replace
          >
            Plan Programs
          </arrow-button>
        </div>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import MainAppBar from '@/layouts/shared/MainAppBar'
import ArrowButton from '@/components/elements/ArrowButton'

import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'PlanInformations',

  components: {
    MainAppBar,
    ArrowButton,
  },

  data() {
    return {
      plan: {},
    }
  },

  computed: {
    ...mapState({
      selectedPlan: (state) => state.plans.selectedPlan,
    }),

    ...mapGetters({
      planPrograms: 'plans/getSelectedPlanPrograms',
    }),

    hasPrograms() {
      return !!this.planPrograms.length
    },

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 1
    },

    next() {
      return this.$attrs.id || false
    },

    currentPlanId() {
      return this.$route.params.id
    },

    isCompleted() {
      return !!this.selectedPlan.completed_at
    },
  },

  methods: {
    ...mapActions({
      getPlan: 'plans/getPlan',
    }),

    ...mapMutations({
      setSelectedPlan: 'plans/setSelectedPlan',
      resetSelectedPlan: 'plans/resetSelectedPlan',
    }),

    async fetchPlan() {
      await this.getPlan(this.currentPlanId).then(({ data }) => {
        this.setSelectedPlan(data)
      })
    },
  },

  watch: {
    selectedPlan: {
      deep: true,
      handler(plan) {
        this.plan = plan
      },
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchPlan()
      }
    })
  },

  beforeRouteLeave(to, from, next) {
    this.resetSelectedPlan()

    next()
  },
}
</script>
